export enum Tags {
    ALL = "all",
    ARTICLE = "article",
    BEGINNER = "beginner",
    BLOG = "blog",
    CHALLENGES = "challenges",
    DEVFEST = "devfest",
    DISCOVERY = "discovery",
    ENGINEERING = "engineering",
    HUMAN_TECH = "human & tech",
    INTERVIEW = "interview",
    LIVE = "live",
    MOTIVATION_CALL = "motivationcall",
    PRESS = "press",
    PUB = "pub",
    QUICKY = "quicky",
    REPLAY = "replay",
    TECH = "tech",
    TECH_NOCODE = "tech & nocode",
    TESTIMONY = "testimony",
    VIDEO = "video",
    WOMEN = "femmes",
    WOMEN_IN_TECH = "womenintech",
    YEAR = "year"
}